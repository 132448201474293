import React, { useEffect, useState, useLayoutEffect } from 'react'
import { FIAT_ORDER } from '@exodus/models'
import { camelCase } from 'lodash'
import pRetry from 'p-retry'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import PageHead from 'src/components/head/PageHead'
import buildImageObject from 'src/js/utils/buildImgObject'
import { useWindowEvent } from 'src/js/utils/hooks'
import { FIAT_SERVER_URL, FIAT_SERVER_URL_SANDBOX } from 'src/constants'

import 'static/purchase/scss/styles.scss'

const imgWidth = 1021
const imgHeight = 434
const imgRatio = imgWidth / imgHeight

const images = {
  bg: {
    aspectRatio: imgRatio,
    path: '/purchase/images/',
    fileType: 'png',
    files: [
      {
        fileName: 'bg_image',
        width: imgWidth,
      },
    ],
  },
}

const {
  ORDER_STATUS: ORDER_STATUS_,
  ORDER_STATUS_TO_BLOCKCHAIN_STATUS,
  ORDER_STATUS_TO_MOONPAY_STATUS,
  ORDER_STATUS_TO_PAYPAL_STATUS,
  ORDER_STATUS_TO_RAMP_STATUS,
  ORDER_STATUS_TO_SARDINE_STATUS,
} = FIAT_ORDER

const ORDER_STATUS = { ...ORDER_STATUS_, unrecognized: 'unrecognized' }

const STATUS_INFO_MAP = {}

for (const statusMap of [
  ORDER_STATUS_TO_BLOCKCHAIN_STATUS,
  ORDER_STATUS_TO_MOONPAY_STATUS,
  ORDER_STATUS_TO_PAYPAL_STATUS,
  ORDER_STATUS_TO_RAMP_STATUS,
  ORDER_STATUS_TO_SARDINE_STATUS,
]) {
  for (const [key, values] of Object.entries(statusMap)) {
    for (const value of values) {
      STATUS_INFO_MAP[camelCase(value)] = key
    }
  }
}

const getTopPos = (id) => {
  let element = document.getElementById(id)

  if (!element) {
    return null
  }

  let top = element.offsetTop
  while ((element = element.offsetParent)) top += element.offsetTop

  return top
}

const getStatusInfo = (status) => STATUS_INFO_MAP[camelCase(status)] ?? ORDER_STATUS.unrecognized

const LOAD_STATES = {
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
  NOT_FOUND: 'not found',
}

const MAX_FETCH_ORDER_RETRIES = 6
const FETCH_ORDER_INTERVAL = 3000

const ERROR_ORDER_NOT_FOUND = {
  code: 404,
  message: 'Order not found',
}

const ContactSupport = ({ text }) => <a href="mailto:support@exodus.com">{text}</a>

const Purchase = ({ location }) => {
  const [loadState, setLoadState] = useState(LOAD_STATES.LOADING)
  const [errDetail, setErrDetail] = useState(null)
  const [details, setDetails] = useState(null)
  const [screenSize, setScreenSize_] = useState({ width: 0, height: 0 })
  const [imgPos, setImgPos] = useState(null)

  const setScreenSize = () => {
    setScreenSize_({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }

  const getOrder = (orderId, serverURL) => async () => {
    const result = await fetch(`${new URL(`/api/order/${orderId}`, serverURL)}`)
    const body = await result.json()

    if (result.ok) {
      return body
    }

    let error = new Error('Failed to fetch order.')

    if (body?.error?.status === 404) {
      error = new Error(body.error.detail || ERROR_ORDER_NOT_FOUND.message)
      error.code = 404
    }

    error.detail = body?.error?.detail
    throw error
  }

  useEffect(() => {
    setScreenSize()

    const url = new URL(location.href)
    const orderId = url.searchParams.get('orderId')
    const sandbox = url.searchParams.get('sandbox')
    const isSandbox = sandbox && sandbox === 'true'
    const fiatServerBase = isSandbox ? FIAT_SERVER_URL_SANDBOX : FIAT_SERVER_URL

    if (!orderId) {
      return
    }

    const fetchOrderAndUpdatePageState = async (orderId) => {
      try {
        const result = await pRetry(getOrder(orderId, fiatServerBase), {
          minTimeout: FETCH_ORDER_INTERVAL,
          maxTimeout: FETCH_ORDER_INTERVAL,
          retries: MAX_FETCH_ORDER_RETRIES,
          factor: 1,
        })

        setDetails(result)
        setLoadState(LOAD_STATES.SUCCESS)
      } catch (error) {
        console.error('Fiat: failed to fetch order', error, {
          url,
          orderId,
          fiatServerBase,
        })

        if (error.code === 404) {
          setLoadState(LOAD_STATES.NOT_FOUND)
        } else {
          setLoadState(LOAD_STATES.ERROR)
          setErrDetail(error.detail)
        }
      }
    }

    fetchOrderAndUpdatePageState(orderId)
  }, [location])

  useWindowEvent('resize', setScreenSize)

  const isLoading = loadState === LOAD_STATES.LOADING
  const isLoaded = loadState === LOAD_STATES.SUCCESS
  const isNotFound = loadState === LOAD_STATES.NOT_FOUND
  const isError = loadState === LOAD_STATES.ERROR

  const statusInfo = isLoaded ? getStatusInfo(details?.status) : undefined
  const readableStatus = statusInfo?.replaceAll('_', ' ').toLowerCase()

  const isFailedOrder = statusInfo === ORDER_STATUS.failed
  const isSuccessfulOrder =
    statusInfo === ORDER_STATUS['in-progress'] || statusInfo === ORDER_STATUS.complete
  const isCompletedOrder = statusInfo === ORDER_STATUS.complete
  const isUnrecognizedOrder = statusInfo === ORDER_STATUS.unrecognized
  const isMalformed = isLoaded && !statusInfo

  const isProblem = isError || isUnrecognizedOrder || isMalformed

  const showBg = isSuccessfulOrder || isFailedOrder || isNotFound || isProblem

  useLayoutEffect(() => {
    if (showBg) {
      const position = getTopPos('bottom-text')

      if (position !== null) {
        setImgPos(position + 70)
      }
    }
  }, [showBg, screenSize])

  const containerHeight = screenSize.height - imgPos
  const containerWidth = screenSize.width

  let marginAdjust = 0
  const containerRatio = containerWidth / containerHeight
  if (containerRatio < imgRatio) {
    const ratioDiff = containerRatio - imgRatio
    marginAdjust = (ratioDiff * imgWidth) / 2
  }

  return (
    <main className="x-page-purchase">
      <div className="x">
        <div className="x__bg-container">
          <div className="x__bg-blue-gradient" />
          <div className="x__bg-purple-gradient" />
          {showBg && (
            <div
              className="x__bg-img-container"
              style={{
                top: `${imgPos}px`,
                marginLeft: marginAdjust,
                marginRight: marginAdjust,
              }}
            >
              <Img
                imgStyle={{
                  objectPosition: 'bottom',
                  objectFit: 'contain',
                }}
                fluid={buildImageObject(images.bg)}
                durationFadeIn={1000}
                alt="Blue convolution"
              />
            </div>
          )}
        </div>
        {isLoading && (
          <div className="x-loader__container">
            <div className="x-loader__outer">
              <div className="x-loader__inner">
                <span className="x-loader__spinner" />
              </div>
            </div>
          </div>
        )}
        {isFailedOrder && (
          <div className="x-content__details-container">
            <div className="x-content__icon-error" />
            <div className="x-content__heading">Order Failed</div>
            <div className="x-content__status" id="bottom-text">
              Please check the status of your payment in History
            </div>
          </div>
        )}
        {isSuccessfulOrder && Boolean(details) && (
          <div className="x-content__details-container">
            <div className="x-content__icon-success" />
            <div className="x-content__heading">Payment {readableStatus}</div>
            <div className="x-content__status" id="bottom-text">
              You paid: {details.fromAmount} {details.fromAsset}
            </div>
            <div className="x-content__status">
              {isCompletedOrder ? 'You received' : "You'll receive"}: {details.toAmount}{' '}
              {details.toAsset}
            </div>
          </div>
        )}
        {isNotFound && (
          <div className="x-content__details-container">
            <div className="x-content__icon-error" />
            <div className="x-content__heading">Payment In Progress</div>
            <div className="x-content__status" id="bottom-text">
              Check your portfolio to see your updated balance.{'\n'}
              <ContactSupport text="Contact support" /> if there is any issue.
            </div>
          </div>
        )}
        {isProblem && (
          <div className="x-content__details-container">
            <div className="x-content__icon-error" />
            <div className="x-content__heading">Problem Fetching Order</div>
            <div className="x-content__status" id="bottom-text">
              {errDetail && `${errDetail}. \n`}
              Please try again or <ContactSupport text="contact support" /> if the issue persists.
            </div>
          </div>
        )}
      </div>
    </main>
  )
}

Purchase.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

Purchase.defaultProps = {}

export default Purchase

// <head> component:
export function Head() {
  return <PageHead page="purchase" noindex />
}
